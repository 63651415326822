import { render, staticRenderFns } from "./mobile_dynamic_mega_bbx.vue?vue&type=template&id=ad6d7440&"
import script from "./mobile_dynamic_mega_bbx.vue?vue&type=script&lang=js&"
export * from "./mobile_dynamic_mega_bbx.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/root/containers/hermes_eshop/components/core/search.vue').default,AccountCard: require('/root/containers/hermes_eshop/components/core/accountCard.vue').default,HyperLink: require('/root/containers/hermes_eshop/components/core/hyperLink.vue').default})
